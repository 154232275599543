import React, { useEffect, useState } from "react";

import axios from "axios";

const TermsAndConditions = ({ API_URI }) => {
  const [content, setContent] = useState();

  useEffect(() => {
    axios
      .post(`${API_URI}/fetch-content/page-terms-and-conditions`)
      .then((res) => {
        setContent(res.data.terms_and_conditions.content);
      });
  }, []);

  return (
    <React.Fragment>
      <main className="text-page">
        <section
          className="hero"
          style={{
            backgroundImage: "linear-gradient(315deg, #00007e 0%, #005c93 74%)",
                backgroundSize: "cover",
                backgroundPosition: "center",
          }}
        >
          <div
            className="container"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default TermsAndConditions;
