import React, { useEffect } from "react";

const Admin = ({ ADMIN_DASHBOARD_LINK }) => {
  // States
  useEffect(() => {
    window.location.replace(ADMIN_DASHBOARD_LINK);
  }, []);

  return <React.Fragment></React.Fragment>;
};

export default Admin;
