import React, { useEffect, useState } from "react";

import axios from "axios";

const Blog = ({ API_URI }) => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    axios.post(`${API_URI}/fetch-content/articles`).then((res) => {
      setArticles(res.data.articles);
    });
  }, []);

  return (
    <React.Fragment>
      <main className="blog-page">
        <section
          className="hero"
          style={{
            backgroundImage: "linear-gradient(315deg, #00007e 0%, #005c93 74%)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="container">
            <h1>
              Latest <br /> <span>News</span>
            </h1>

            <div className="boxes">
              {articles.map((article, index) => {
                return (
                  <a
                    href={article.link}
                    target="_blank"
                    key={index}
                    className="box"
                  >
                    <img src={article.image} alt="" />

                    <h3>{article.title}</h3>
                  </a>
                );
              })}
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default Blog;
