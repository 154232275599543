import React from "react";

const WhitelistRegistration = ({
  connectWallet,
  walletConnected,
  walletAddress,
  day1,
  day2,
  hour1,
  hour2,
  minute1,
  minute2,
  second1,
  second2,
  whitelistEmail,
  setWhitelistEmail,
  getWhitelisted,
}) => {
  return (
    <React.Fragment>
      <main className="whitelist-registration-page">
        <section
          className="hero"
          style={{
            backgroundImage: "linear-gradient(315deg, #00007e 0%, #005c93 74%)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <form onSubmit={getWhitelisted} className="container">
            <h1>
              Get Exclusive <br /> <span>Access</span>
            </h1>

            <div className="timer">
              <div className="timer-box">
                <div className="timer-box-top">
                  <p>
                    {day1}
                    {day2}
                  </p>
                </div>

                <span>Days</span>
              </div>

              <div className="timer-box">
                <div className="timer-box-top">
                  <p>
                    {hour1}
                    {hour2}
                  </p>
                </div>

                <span>Hours</span>
              </div>

              <div className="timer-box">
                <div className="timer-box-top">
                  <p>
                    {minute1}
                    {minute2}
                  </p>
                </div>

                <span>Minutes</span>
              </div>

              <div className="timer-box">
                <div className="timer-box-top">
                  <p>
                    {second1}
                    {second2}
                  </p>
                </div>

                <span>Seconds</span>
              </div>
            </div>

            {walletConnected ? (
              <React.Fragment>
                <input
                  type="email"
                  placeholder="Enter your email address"
                  onChange={(e) => {
                    setWhitelistEmail(e.target.value);
                  }}
                  value={whitelistEmail}
                  required
                />
                <button>Submit</button>
              </React.Fragment>
            ) : (
              <button onClick={connectWallet} type="button">
                Connect Wallet
              </button>
            )}
          </form>

          <img
            src="/images/pages/whitelist-registration/hero-img-1.png"
            alt=""
            className="hero-img-1"
          />
        </section>
      </main>
    </React.Fragment>
  );
};

export default WhitelistRegistration;
