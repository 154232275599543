import React, { useEffect, useState } from "react";

import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";

import Web3 from "web3";
import Web3Modal from "web3modal";

import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";

import axios from "axios";

import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import Scroller from "./components/Scroller";
import NavigationBar from "./components/NavigationBar";

// Pages
import ComingSoon from "./pages/ComingSoon";
import Entry from "./pages/Entry";
import Landing from "./pages/Landing";
import WhitelistRegistration from "./pages/WhitelistRegistration";
import RarityChart from "./pages/RarityChart";
import WhitelistSaleMint from "./pages/WhitelistSaleMint";
import PublicSaleMint from "./pages/PublicSaleMint";
import Collection from "./pages/Collection";
import Admin from "./pages/Admin";
import TermsOfUse from "./pages/TermsOfUse";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyNotice from "./pages/PrivacyNotice";
import Blog from "./pages/Blog";

// Config
import {
  // Network
  NETWORK,
  NETWORK_NAME,
  NETWORK_ID,
  NETWORK_CURRENCY_SYMBOL,
  NETWORK_RPC_URLS,
  NETWORK_BLOCK_EXPLORER_URLS,

  // Etherscan
  ETHERSCAN_API_URI,
  ETHERSCAN_API_KEY,

  // Pinata
  PINATA_GATEWAY_URI,

  // Smart Contract
  SMART_CONTRACT_ADDRESS,
  SMART_CONTRACT_ABI,

  // Supplies
  MAX_SUPPLY,

  // URIs
  BASE_URI,
  HIDDEN_URI,

  // Costs
  WHITELIST_SALE_COST,
  PUBLIC_SALE_COST,

  // Limits
  WHITELIST_SALE_PER_ADDRESS_LIMIT,
  PUBLIC_SALE_PER_ADDRESS_LIMIT,

  // Whitelists
  WHITELIST_SALE_WHITELIST,

  // Opensea
  OPENSEA_BASE_LINK,
  OPENSEA_COLLECTION_LINK,

  // Countdown
  COUNTDOWN_DATE,

  // API
  API_URI,

  // Admin
  ADMIN_DASHBOARD_LINK,
} from "./config";

const App = () => {
  // States
  const history = useHistory();

  const ipfsGatewayTools = new IPFSGatewayTools();

  const [walletConnected, setWalletConnected] = useState(false);
  const [fullWalletAddress, setFullWalletAddress] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);

  const [mintedModalOpened, setMintedModalOpened] = useState(false);
  const [mintedModalNFTs, setMintedModalNFTs] = useState([]);

  const [nftModalOpened, setNFTModalOpened] = useState(false);
  const [nftModalNFT, setNFTModalNFT] = useState({});

  const [collectionNFTs, setCollectionNFTs] = useState([]);

  const [mintQuantity, setMintQuantity] = useState(1);
  const [totalMinted, setTotalMinted] = useState(0);

  const [transferAddress, setTransferAddress] = useState(null);

  const [day1, setDay1] = useState(0);
  const [day2, setDay2] = useState(0);

  const [hour1, setHour1] = useState(0);
  const [hour2, setHour2] = useState(0);

  const [minute1, setMinute1] = useState(0);
  const [minute2, setMinute2] = useState(0);

  const [second1, setSecond1] = useState(0);
  const [second2, setSecond2] = useState(0);

  const [whitelistEmail, setWhitelistEmail] = useState(null);

  // DAPP Functions
  const connectWallet = async () => {
    if (Web3.givenProvider) {
      const providerOptions = {};

      const web3Modal = new Web3Modal({
        network: "mainnet",
        cacheProvider: true,
        providerOptions,
      });

      const provider = await web3Modal.connect();
      const web3 = new Web3(provider);

      web3.eth.net.getId();

      const addresses = await web3.eth.getAccounts();
      const address = addresses[0];

      const { ethereum } = window;

      const networkId = await ethereum.request({
        method: "net_version",
      });

      // Testnet
      if (NETWORK === "Testnet") {
        if (networkId === NETWORK_ID || networkId === `${NETWORK_ID}`) {
          setWalletConnected(true);

          setFullWalletAddress(address);

          let trimmedWalletAddress =
            address.slice(0, 7) + "..." + address.slice(34, -1);
          setWalletAddress(trimmedWalletAddress);

          const contract = new web3.eth.Contract(
            SMART_CONTRACT_ABI,
            SMART_CONTRACT_ADDRESS
          );

          contract.methods
            .totalSupply()
            .call()
            .then((res) => {
              setTotalMinted(res);
            });
        } else {
          toast.error(`Please change the network to ${NETWORK_NAME}`);

          await web3.currentProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: Web3.utils.toHex(NETWORK_ID) }],
          });

          setWalletConnected(true);
        }
      }

      // Mainnet
      else if (NETWORK === "Mainnet") {
        if (networkId === NETWORK_ID || networkId === `${NETWORK_ID}`) {
          setWalletConnected(true);
        } else {
          toast.error(`Please change the network to ${NETWORK_NAME}`);

          await web3.currentProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: Web3.utils.toHex(NETWORK_ID) }],
          });
          
          setWalletConnected(true);
        }
      }
    } else {
      window.open(`https://metamask.app.link/dapp/${window.location.href}`);
    }
  };

  useEffect(() => {
    const web3 = new Web3(Web3.givenProvider);
   const contract = new web3.eth.Contract(
            SMART_CONTRACT_ABI,
            SMART_CONTRACT_ADDRESS
          );

          contract.methods
          .totalSupply()
          .call()
          .then((res) => {
            setTotalMinted(--res);
          });
    // axios
    //   .get(
    //     `${ETHERSCAN_API_URI}?module=stats&action=tokensupply&contractaddress=${SMART_CONTRACT_ADDRESS}&apikey=${ETHERSCAN_API_KEY}`
    //   )
    //   .then(function (response) {
    //     setTotalMinted(response.data.result);
    //   });
  }, []);

  const whitelistSaleMint = async () => {
    if (walletConnected) {
      const web3 = new Web3(Web3.givenProvider);
      await Web3.givenProvider.enable();

      const price = WHITELIST_SALE_COST * mintQuantity;
      var tokens = web3.utils.toWei(price.toString(), "ether");
      var bntokens = web3.utils.toBN(tokens);

      const contract = new web3.eth.Contract(
        SMART_CONTRACT_ABI,
        SMART_CONTRACT_ADDRESS
      );

      const addresses = await web3.eth.getAccounts();
      const address = addresses[0];

      const leaves = WHITELIST_SALE_WHITELIST.map((x) => keccak256(x));
      const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
      const buf2hex = (x) => "0x" + x.toString("hex");

      const leaf = keccak256(address);
      const proof = tree.getProof(leaf).map((x) => buf2hex(x.data));

      if (WHITELIST_SALE_WHITELIST.indexOf(address) !== -1) {
        contract.methods
          .addressWhitelistSaleMintedBalance(address)
          .call()
          .then((mintedBalance) => {
            if (
              parseInt(mintedBalance) < WHITELIST_SALE_PER_ADDRESS_LIMIT &&
              mintQuantity + parseInt(mintedBalance) <=
                WHITELIST_SALE_PER_ADDRESS_LIMIT
            ) {
              contract.methods
                .whitelistSaleMint(mintQuantity, proof)
                .send({ gasLimit: "300000", from: address, value: bntokens })
                .then((nfts) => {
                  setMintedModalOpened(true);

                  if (
                    Object.prototype.toString.call(nfts.events.Transfer) ===
                    "[object Array]"
                  ) {
                    nfts.events.Transfer.forEach((nft) => {
                      contract.methods
                        .tokenURI(nft.returnValues.tokenId)
                        .call()
                        .then((tokenURI) => {
                          axios.post(tokenURI).then((res) => {
                            setMintedModalNFTs((prev) => {
                              return [...prev, res.data];
                            });
                          });
                        });
                    });
                  } else if (
                    Object.prototype.toString.call(nfts.events.Transfer) ===
                    "[object Object]"
                  ) {
                    contract.methods
                      .tokenURI(nfts.events.Transfer.returnValues.tokenId)
                      .call()
                      .then((tokenURI) => {
                        axios.post(tokenURI).then((res) => {
                          setMintedModalNFTs((prev) => {
                            return [...prev, res.data];
                          });
                        });
                      });
                  }

                  contract.methods
                    .totalSupply()
                    .call()
                    .then((response) => {
                      setTotalMinted(response);
                    });
                });
            } else {
              toast.error(
                `You can mint max ${WHITELIST_SALE_PER_ADDRESS_LIMIT} nfts on public sale`
              );
            }
          });
      } else {
        toast.error("You are not whitelisted");
      }
    }
  };

  const publicSaleMint = async () => {
    if (walletConnected) {
      const web3 = new Web3(Web3.givenProvider);
      await Web3.givenProvider.enable();

      const price = PUBLIC_SALE_COST * mintQuantity;
      var tokens = web3.utils.toWei(price.toString(), "ether");
      var bntokens = web3.utils.toBN(tokens);

      const contract = new web3.eth.Contract(
        SMART_CONTRACT_ABI,
        SMART_CONTRACT_ADDRESS
      );

      const addresses = await web3.eth.getAccounts();
      const address = addresses[0];

      contract.methods
        .addressMintedBalance(address)
        .call()
        .then((mintedBalance) => {
          if (
            parseInt(mintedBalance) < PUBLIC_SALE_PER_ADDRESS_LIMIT &&
            mintQuantity + parseInt(mintedBalance) <=
              PUBLIC_SALE_PER_ADDRESS_LIMIT
          ) {
            contract.methods
              .publicSaleMint(mintQuantity)
              .send({ gasLimit: "300000", from: address, value: bntokens })
              .then((nfts) => {
                setMintedModalOpened(true);

                if (
                  Object.prototype.toString.call(nfts.events.Transfer) ===
                  "[object Array]"
                ) {
                  nfts.events.Transfer.forEach((nft) => {
                    contract.methods
                      .tokenURI(nft.returnValues.tokenId)
                      .call()
                      .then((tokenURI) => {
                        axios.post(tokenURI).then((res) => {
                          setMintedModalNFTs((prev) => {
                            return [...prev, res.data];
                          });
                        });
                      });
                  });
                } else if (
                  Object.prototype.toString.call(nfts.events.Transfer) ===
                  "[object Object]"
                ) {
                  contract.methods
                    .tokenURI(nfts.events.Transfer.returnValues.tokenId)
                    .call()
                    .then((tokenURI) => {
                      axios.post(tokenURI).then((res) => {
                        setMintedModalNFTs((prev) => {
                          return [...prev, res.data];
                        });
                      });
                    });
                }

                contract.methods
                  .totalSupply()
                  .call()
                  .then((response) => {
                    setTotalMinted(response);
                  });
              });
          } else {
            toast.error(
              `You can mint max ${PUBLIC_SALE_PER_ADDRESS_LIMIT} nfts on public sale`
            );
          }
        });
    }
  };

  useEffect(async () => {
    if (walletConnected) {
      const web3 = new Web3(Web3.givenProvider);
      await Web3.givenProvider.enable();
  
      const contract = new web3.eth.Contract(
        SMART_CONTRACT_ABI,
        SMART_CONTRACT_ADDRESS
      );
  
      const addresses = await web3.eth.getAccounts();
      const address = addresses[0];
  
      contract.methods
        .walletOfOwner(address)
        .call()
        .then((wallet) => {
          console.log(wallet);
  
          wallet.forEach((nft) => {
            const jsonURI = `${PINATA_GATEWAY_URI}/${nft}.json`;
  
            axios
              .get(jsonURI, {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then((res) => {
                console.log(res);
                const data = res.data;
  
               
                data.tokenID = nft;
                if (data.attributes) {
                  data.image = `https://bafybeihu5sdkk53ribabqkxrnowwitu5sg4qmmqvcih55ynec46srxisp4.ipfs.dweb.link/${(Number(data.tokenID)).toString()}.png`;
                }
  
  
                setCollectionNFTs((prev) => [...prev, data]);
              })
              .catch((error) => {
                console.error('Error fetching JSON:', error);
              });
          });
        });
    }
  }, [walletConnected]);

  const transferNFT = async (tokenID) => {
    const web3 = new Web3(Web3.givenProvider);
    await Web3.givenProvider.enable();

    const contract = new web3.eth.Contract(
      SMART_CONTRACT_ABI,
      SMART_CONTRACT_ADDRESS
    );

    const addresses = await web3.eth.getAccounts();
    const address = addresses[0];

    contract.methods
      .safeTransferFrom(address, transferAddress, tokenID)
      .send({
        from: address,
      })
      .then(() => {
        toast.success("NFT transferred successfully");

        setCollectionNFTs([]);

        setNFTModalOpened(false);
        setNFTModalNFT({});

        contract.methods
          .walletOfOwner(address)
          .call()
          .then((wallet) => {
            wallet.forEach((nft) => {
              contract.methods
                .tokenURI(nft)
                .call()
                .then((tokenURI) => {
                  axios.post(tokenURI).then((res) => {
                    if (res.data.attributes) {
                      res.data.image = ipfsGatewayTools.convertToDesiredGateway(
                        res.data.image,
                        PINATA_GATEWAY_URI
                      );
                    }

                    res.data.tokenID = nft;

                    setCollectionNFTs((prev) => {
                      return [...prev, res.data];
                    });
                  });
                });
            });
          });
      });
  };

  // Countdown
  const countDown = () => {
    const dueDate = new Date(COUNTDOWN_DATE).getTime();
    const currentDate = new Date().getTime();

    const remainings = dueDate - currentDate;

    if (remainings > 0) {
      let second = 1000;
      let minute = second * 60;
      let hour = minute * 60;
      let day = hour * 24;

      // Remainings
      let remainingDay = Math.floor(remainings / day);
      let remainingHour = Math.floor((remainings % day) / hour);
      let remainingMinute = Math.floor((remainings % hour) / minute);
      let remainingSecond = Math.floor((remainings % minute) / second);

      // Day
      let dayLength = remainingDay.toString().length;

      if (dayLength === 1) {
        setDay1(0);
        setDay2(remainingDay);
      } else if (dayLength === 2) {
        remainingDay = remainingDay.toString().split("");

        setDay1(remainingDay[0]);
        setDay2(remainingDay[1]);
      }

      // Hour
      let hourLength = remainingHour.toString().length;

      if (hourLength === 1) {
        setHour1(0);
        setHour2(remainingHour);
      } else if (hourLength === 2) {
        remainingHour = remainingHour.toString().split("");

        setHour1(remainingHour[0]);
        setHour2(remainingHour[1]);
      }

      // Minute
      let minuteLength = remainingMinute.toString().length;

      if (minuteLength === 1) {
        setMinute1(0);
        setMinute2(remainingMinute);
      } else if (minuteLength === 2) {
        remainingMinute = remainingMinute.toString().split("");

        setMinute1(remainingMinute[0]);
        setMinute2(remainingMinute[1]);
      }

      // Second
      let secondLength = remainingSecond.toString().length;

      if (secondLength === 1) {
        setSecond1(0);
        setSecond2(remainingSecond);
      } else if (secondLength === 2) {
        remainingSecond = remainingSecond.toString().split("");

        setSecond1(remainingSecond[0]);
        setSecond2(remainingSecond[1]);
      }
    } else {
      setDay1(0);
      setDay2(0);

      setHour1(0);
      setHour2(0);

      setMinute1(0);
      setMinute2(0);

      setSecond1(0);
      setSecond2(0);
    }
  };

  setInterval(countDown, 1000);

  // Whitelist Functions
  const getWhitelisted = (e) => {
    e.preventDefault();

    axios
      .post(
        `${API_URI}/get-whitelisted?wallet_address=${fullWalletAddress}&email=${whitelistEmail}`
      )
      .then((res) => {
        if (res.data.whitelisted === true) {
          toast.error(`You are already whitelisted`);
        } else {
          toast.success(`You are now whitelisted`);

          setWhitelistEmail(null);

          setTimeout(function () {
            window.location.replace("/");
          }, 1100);
        }
      });
  };

  return (
    <React.Fragment>
      <BrowserRouter>
        <Scroller />

        <ToastContainer
          theme="colored"
          position="top-center"
          autoClose={1000}
        />

        <NavigationBar
          API_URI={API_URI}
          connectWallet={connectWallet}
          walletConnected={walletConnected}
          walletAddress={walletAddress}
        />

        <Switch>
          <Route path="/ComingSoon" exact>
            <ComingSoon
              day1={day1}
              day2={day2}
              hour1={hour1}
              hour2={hour2}
              minute1={minute1}
              minute2={minute2}
              second1={second1}
              second2={second2}
            />
          </Route>

          <Route path="/" exact>
            {/* <Entry /> */}
            <Landing
              API_URI={API_URI}
              MAX_SUPPLY={MAX_SUPPLY}
              totalMinted={totalMinted}
              day1={day1}
              day2={day2}
              hour1={hour1}
              hour2={hour2}
              minute1={minute1}
              minute2={minute2}
              second1={second1}
              second2={second2}
            />
          </Route>

          <Route path="/whitelist-registration" exact>
            <WhitelistRegistration
              connectWallet={connectWallet}
              walletConnected={walletConnected}
              walletAddress={walletAddress}
              day1={day1}
              day2={day2}
              hour1={hour1}
              hour2={hour2}
              minute1={minute1}
              minute2={minute2}
              second1={second1}
              second2={second2}
              whitelistEmail={whitelistEmail}
              setWhitelistEmail={setWhitelistEmail}
              getWhitelisted={getWhitelisted}
            />
          </Route>

          {/* <Route path="/rarity-chart" exact>
            <RarityChart />
          </Route> */}

          <Route path="/mint" exact>
            {/* <WhitelistSaleMint
              NETWORK_CURRENCY_SYMBOL={NETWORK_CURRENCY_SYMBOL}
              MAX_SUPPLY={MAX_SUPPLY}
              WHITELIST_SALE_COST={WHITELIST_SALE_COST}
              WHITELIST_SALE_PER_ADDRESS_LIMIT={
                WHITELIST_SALE_PER_ADDRESS_LIMIT
              }
              connectWallet={connectWallet}
              walletConnected={walletConnected}
              mintedModalOpened={mintedModalOpened}
              setMintedModalOpened={setMintedModalOpened}
              mintedModalNFTs={mintedModalNFTs}
              mintQuantity={mintQuantity}
              setMintQuantity={setMintQuantity}
              totalMinted={totalMinted}
              whitelistSaleMint={whitelistSaleMint}
            /> */}

            <PublicSaleMint
              NETWORK_CURRENCY_SYMBOL={NETWORK_CURRENCY_SYMBOL}
              MAX_SUPPLY={MAX_SUPPLY}
              PUBLIC_SALE_COST={PUBLIC_SALE_COST}
              PUBLIC_SALE_PER_ADDRESS_LIMIT={PUBLIC_SALE_PER_ADDRESS_LIMIT}
              connectWallet={connectWallet}
              walletConnected={walletConnected}
              mintedModalOpened={mintedModalOpened}
              setMintedModalOpened={setMintedModalOpened}
              mintedModalNFTs={mintedModalNFTs}
              mintQuantity={mintQuantity}
              setMintQuantity={setMintQuantity}
              totalMinted={totalMinted}
              publicSaleMint={publicSaleMint}
            />
          </Route>

          <Route path="/collection" exact>
            <Collection
              SMART_CONTRACT_ADDRESS={SMART_CONTRACT_ADDRESS}
              OPENSEA_BASE_LINK={OPENSEA_BASE_LINK}
              walletConnected={walletConnected}
              nftModalOpened={nftModalOpened}
              setNFTModalOpened={setNFTModalOpened}
              nftModalNFT={nftModalNFT}
              setNFTModalNFT={setNFTModalNFT}
              collectionNFTs={collectionNFTs}
              transferAddress={transferAddress}
              setTransferAddress={setTransferAddress}
              transferNFT={transferNFT}
            />
          </Route>

          <Route path="/admin" exact>
            <Admin ADMIN_DASHBOARD_LINK={ADMIN_DASHBOARD_LINK} />
          </Route>

          <Route path="/terms-of-use" exact>
            <TermsOfUse API_URI={API_URI} />
          </Route>

          <Route path="/terms-and-conditions" exact>
            <TermsAndConditions API_URI={API_URI} />
          </Route>

          <Route path="/privacy-notice" exact>
            <PrivacyNotice API_URI={API_URI} />
          </Route>

          <Route path="/blog" exact>
            <Blog API_URI={API_URI} />
          </Route>
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
