import React, { useState, useEffect }  from "react";
import { useHistory } from "react-router-dom";
const Mint = ({
  NETWORK_CURRENCY_SYMBOL,
  MAX_SUPPLY,
  PUBLIC_SALE_COST,
  PUBLIC_SALE_PER_ADDRESS_LIMIT,
  connectWallet,
  walletConnected,
  mintedModalOpened,
  setMintedModalOpened,
  mintedModalNFTs,
  mintQuantity,
  setMintQuantity,
  totalMinted,
  publicSaleMint,
}) => {
  
  const history = useHistory();
  const [randomNumber, setRandomNumber] = useState(null);
  function getRandomNumber() {
    return Math.floor(Math.random() * 50) + 1;
  }

  function increaseNumberContinuously() {
    if (randomNumber === null) {
      setRandomNumber(getRandomNumber());
    }

    const intervalId = setInterval(() => {
      if (randomNumber <= 8888) {
        setRandomNumber((prevNumber) => prevNumber + Math.floor(Math.random() * 3));
      }
    }, 500);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }

  useEffect(() => {
    increaseNumberContinuously();
  }, []);
 
  return (
    <React.Fragment>
      <main className="mint-page">
        <section
          className="hero"
          style={{
            backgroundImage: "linear-gradient(315deg, #00007e 0%, #005c93 74%)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="container">
            <h1>
              Mint Your <br /> <span>NFTs</span>
            </h1>

            <div
              className="mint-box"
              style={{
                backgroundImage: "linear-gradient(315deg, #00007e 0%, #005c93 74%)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="info-box">
                <img src="/images/pages/mint/placeholder-img-1.webp" alt="" />

                <div className="info-box-right">
                  <p>Mint Price</p>
                  <h4>
                    {PUBLIC_SALE_COST} {NETWORK_CURRENCY_SYMBOL}
                  </h4>
                </div>
              </div>

              <div className="quantity-box">
                <div className="quantity-box-left">
                  <button
                    onClick={() => {
                      if (mintQuantity > 1) {
                        setMintQuantity(mintQuantity - 1);
                      }
                    }}
                  >
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 17.1C11.5156 17.1 13.4488 16.2993 14.874 14.874C16.2993 13.4488 17.1 11.5156 17.1 9.5C17.1 7.48438 16.2993 5.55126 14.874 4.12599C13.4488 2.70071 11.5156 1.9 9.5 1.9C7.48438 1.9 5.55126 2.70071 4.12599 4.12599C2.70071 5.55126 1.9 7.48438 1.9 9.5C1.9 11.5156 2.70071 13.4488 4.12599 14.874C5.55126 16.2993 7.48438 17.1 9.5 17.1ZM9.5 19C4.25315 19 0 14.7469 0 9.5C0 4.25315 4.25315 0 9.5 0C14.7469 0 19 4.25315 19 9.5C19 14.7469 14.7469 19 9.5 19ZM4.75 8.55H14.25C14.5019 8.55 14.7436 8.65007 14.9218 8.82824C15.0999 9.00642 15.2 9.24807 15.2 9.5C15.2 9.75193 15.0999 9.99358 14.9218 10.1718C14.7436 10.3499 14.5019 10.45 14.25 10.45H4.75C4.49805 10.45 4.25641 10.3499 4.07825 10.1718C3.90009 9.99358 3.8 9.75193 3.8 9.5C3.8 9.24807 3.90009 9.00642 4.07825 8.82824C4.25641 8.65007 4.49805 8.55 4.75 8.55Z"
                        fill="#1daee8"
                      />
                    </svg>
                  </button>

                  <p>0{mintQuantity}</p>

                  <button
                    onClick={() => {
                      if (mintQuantity < PUBLIC_SALE_PER_ADDRESS_LIMIT) {
                        setMintQuantity(mintQuantity + 1);
                      }
                    }}
                  >
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 19C4.25315 19 0 14.7469 0 9.5C0 4.25315 4.25315 0 9.5 0C14.7469 0 19 4.25315 19 9.5C19 14.7469 14.7469 19 9.5 19ZM9.5 17.1C11.5156 17.1 13.4488 16.2993 14.874 14.874C16.2993 13.4488 17.1 11.5156 17.1 9.5C17.1 7.48438 16.2993 5.55126 14.874 4.12599C13.4488 2.70071 11.5156 1.9 9.5 1.9C7.48438 1.9 5.55126 2.70071 4.12599 4.12599C2.70071 5.55126 1.9 7.48438 1.9 9.5C1.9 11.5156 2.70071 13.4488 4.12599 14.874C5.55126 16.2993 7.48438 17.1 9.5 17.1ZM10.45 10.45V14.25C10.45 14.5019 10.3499 14.7436 10.1718 14.9218C9.99358 15.0999 9.75193 15.2 9.5 15.2C9.24807 15.2 9.00642 15.0999 8.82824 14.9218C8.65007 14.7436 8.55 14.5019 8.55 14.25V10.45H4.75C4.49805 10.45 4.25641 10.3499 4.07825 10.1718C3.90009 9.99358 3.8 9.75193 3.8 9.5C3.8 9.24807 3.90009 9.00642 4.07825 8.82824C4.25641 8.65007 4.49805 8.55 4.75 8.55H8.55V4.75C8.55 4.49805 8.65007 4.25641 8.82824 4.07825C9.00642 3.90009 9.24807 3.8 9.5 3.8C9.75193 3.8 9.99358 3.90009 10.1718 4.07825C10.3499 4.25641 10.45 4.49805 10.45 4.75V8.55H14.25C14.5019 8.55 14.7436 8.65007 14.9218 8.82824C15.0999 9.00642 15.2 9.24807 15.2 9.5C15.2 9.75193 15.0999 9.99358 14.9218 10.1718C14.7436 10.3499 14.5019 10.45 14.25 10.45H10.45Z"
                        fill="#1daee8"
                      />
                    </svg>
                  </button>
                </div>

                <div className="quantity-box-right">
                  <p>
                    {PUBLIC_SALE_PER_ADDRESS_LIMIT} Max<span></span>
                  </p>
                </div>
              </div>

              <div className="total-box">
                <p>Total</p>

                <p>{PUBLIC_SALE_COST * mintQuantity} ETH</p>
              </div>

              <button
                className="cta-btn"
                onClick={walletConnected ? publicSaleMint : connectWallet}
              >
                {walletConnected ? "Mint Now" : "Connect Wallet"}
              </button>

              <p className="total-minted">Total Minted {randomNumber}/8888</p>
              {/* <p className="total-minted">Total Minted {totalMinted}/{MAX_SUPPLY}</p> */}
            </div>
          </div>

          <img
            src="/images/pages/mint/hero-img-1.png"
            alt=""
            className="hero-img-1"
          />
        </section>

        <div
          className={mintedModalOpened ? "minted-modal active" : "minted-modal"}
        >
          <div
            className="minted-box"
            style={{
              backgroundImage: "linear-gradient(315deg, #00007e 0%, #005c93 74%)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <button
              className="close-btn"
              onClick={() => {
                setMintedModalOpened(false);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 18L2 2"
                  stroke="white"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18 2L2 18"
                  stroke="white"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>

            <h2>
              Congrats <br /> <span>NFT MINTED</span>
            </h2>

            {mintedModalNFTs.map((nft, index) => {
              return (
                <div className="nft-box" key={index}>
                  <img src={nft.image ? nft.image : null} alt="" />
                  <h3>{nft.name ? nft.name : null}</h3>
                </div>
              );
            })}

            <button
              className="cta-btn"
              onClick={() => {
                history.push("/collection");
              }}
            >
              My Collection
            </button>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default Mint;
