import React, { useState } from "react";

import { useHistory } from "react-router-dom";

const ComingSoon = ({
  day1,
  day2,
  hour1,
  hour2,
  minute1,
  minute2,
  second1,
  second2,
}) => {
  // States
  const history = useHistory();

  const [characterActive, setCharacterActive] = useState(false);

  return (
    <React.Fragment>
      <main className="entry-page">
        <section
          className="hero"
          style={{
            backgroundImage: "linear-gradient(315deg, #00007e 0%, #005c93 74%)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="container">
            <h1>
              Welcome to the <br /> <span>Community</span>
            </h1>

            <div className="timer">
              <div className="timer-box">
                <div className="timer-box-top">
                  <p>
                    {day1}
                    {day2}
                  </p>
                </div>

                <span>Days</span>
              </div>

              <div className="timer-box">
                <div className="timer-box-top">
                  <p>
                    {hour1}
                    {hour2}
                  </p>
                </div>

                <span>Hours</span>
              </div>

              <div className="timer-box">
                <div className="timer-box-top">
                  <p>
                    {minute1}
                    {minute2}
                  </p>
                </div>

                <span>Minutes</span>
              </div>

              <div className="timer-box">
                <div className="timer-box-top">
                  <p>
                    {second1}
                    {second2}
                  </p>
                </div>

                <span>Seconds</span>
              </div>
            </div>

            <button
              onClick={() => {
                setCharacterActive(true);

                setTimeout(function () {
                  history.push("/whitelist-registration");
                }, 150);
              }}
            >
              Join Pre-Mint
            </button>
            <img
              src="/images/pages/entry/character.webp"
              alt=""
              className={characterActive ? "character active" : "character"}
            />
          </div>

          <img
            src="/images/pages/entry/hero-img-1.png"
            alt=""
            className="hero-img-1"
          />
        </section>
      </main>
    </React.Fragment>
  );
};

export default ComingSoon;
